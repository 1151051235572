@import "../styles/helpers"

.appBar
    display: flex
    flex-grow: 1
    align-items: center
    margin-top: 5px

.appBarLogo
    display: none
    +md
        display: block
.drawer
    display: flex
    flex-direction: column
    min-height: 100vh
    margin: 0
    padding: 0
    overflow: hidden

.logo
    margin-top: 20px
    width: 260px

.navList
    display: flex
    flex-direction: column
    gap: 8px
    padding-left: 2px
    padding-right: 2px
    margin-top: 20px
    margin-bottom: 20px
    flex-grow: 1
    backdrop-filter: blur(40px)
    background-color: #ffffff1c

.listText
    // background: rgba(235,235,235,0.25)
    // padding: 5px
    border-radius: 8px
    margin-left: 15px
    // display: flex
    // align-items: center
    // justify-content: center
    font-family: 'Poppins', sans-serif
    color: white

.imageBox
    display: flex
    justify-content: space-between
    align-items: center

.navBar
    display: flex
    justify-content: space-around
    gap: 16px
    align-items: center
    border-radius: 20px
    +sm
    background: black

.icon
    color: white
    font-size: 30px

.icon1
    color: white
    font-size: 24px
    // +sm 
    //    color: white
.navbarflex
    display: flex
    gap: 20px
    +xs
        gap: 0px

.link
    display: flex
    text-decoration: none
    color: white
    align-items: center
    font-size: 16px
    font-weight: 500

    &:hover
        display: flex
        flex-direction: column
        text-decoration: none
        color: white
        align-items: flex-end
        justify-content: flex-end
        justify-items: flex-end
        font-size: 16px
        color: #ADFF00

.active
    display: flex
    flex-direction: column
    text-decoration: none
    color: #ADFF00
    align-items: flex-end
    justify-content: flex-end
    justify-items: flex-end
    font-size: 16px
    font-weight: 500
.logoimage
    cursor: pointer
    width: 56px


.navlogoimage
    cursor: pointer
    margin: 10px auto
    width: 35%

.usd
    display: flex
    color: white
    padding: 0 10px

.cusd
    background-color: #ADFF00
    border-radius: 30px
    width: 100%
    color: #111111
    font-weight: bold
    padding: 8px 16px 8px 16px
    text-align: center
    position: relative
    left: 10px
    font-family: 'Roboto'

.busd
    background-color: #313131
    border-radius: 30px
    width: 100%
    color: white
    padding: 8px 16px 8px 16px
    text-align: center
    font-family: 'Roboto'
.img1
    width: 50px
    +sm
        width: 30px
.navbarflex
    display: flex
    gap: 20px
    +xs
        gap: 0px
