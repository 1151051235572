// common styles
@import ./helpers
@import ./variables
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:wght@100;200;300;400;500;600;700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap')
html,
body
  // background-color: black
  // background-image: url('../assets/Images/bggolden.png')
  background: linear-gradient(to top, black 0%, #ffbb10 100%)
  // background: linear-gradient(120deg,  #ffbb10, black)//
  // background-image: linear-gradient(to bottom right, #ffbb10, black)
  background-position: top center
  background-size: cover
  background-repeat: no-repeat
  background-attachment: fixed
  font-family: 'Roboto', sans-serif
  min-height: 100vh
  overflow-y: auto
  margin: 0
  padding: 0
